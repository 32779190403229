<div class="modal-header">
  <h2 mat-dialog-title trans>Project Settings</h2>
  <button type="button" (click)="close()" class="close-button no-style">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-tab-group color="accent" animationDuration="0">
  <mat-tab [label]="'Site URL' | trans">
    <site-url-tab></site-url-tab>
  </mat-tab>
  <mat-tab [label]="'Forms' | trans">
    <form
      [formGroup]="projectSettings.form"
      (ngSubmit)="projectSettings.submitForm()"
      ngNativeValidate
    >
      <div class="tab-content" [formGroup]="projectSettings.form">
        <p [transValues]="{siteName: settings.get('branding.site_name')}" trans>
          When a form is submitted on your published website, :siteName will collect the submission
          and send it to this email address.
        </p>
        <div class="input-container">
          <label for="project-settings-email" trans>Send form submissions to:</label>
          <input type="email" id="project-settings-email" formControlName="formsEmail" required />
        </div>
        <br>

        <ng-container formGroupName="webhooks">
        <h3>
          WebHooks Integration
        </h3>

        <div class="input-container">
          <label for="project-settings-header-value" trans>Key app.kreasiads.com <span style="color: red">*</span></label>
          <input type="header-value" id="project-settings-header-value" formControlName="HeaderValue" required />
        </div>
       
        </ng-container>
        <br>
        <ng-container formGroupName="telegram">
          <h3>
            Telegram Notifications
          </h3>
          
          <p trans>
            Please invite this bot https://t.me/kreasiads_bot to your favorite group, and enter your Telegram Group ID below
          </p>
          <div class="input-container">
            <label for="project-settings-telegram-notifications-chat" trans>Group ID <span style="color: red">*</span></label>
            <input type="telegram-notifications-chat" id="project-settings-telegram-notifications-chat" formControlName="TelegramNotifications" required />
          </div>
         <br>
          </ng-container>

        <ng-container formGroupName="tracking">
          <h3>
            Meta Ads
          </h3>
          
          <p trans>
            Enter your pixel ID from meta ads
          </p>
          <div class="input-container">
            <label for="project-settings-pixel-id-1" trans>PIXEL ID 1 <span style="color: red">*</span></label>
            <input type="pixel-id-1" id="project-settings-pixel-id-1" formControlName="PixelID1" required />
          </div>
         <br>

         <h3>
          Google Tag Manager
        </h3>
        
        <p trans>
          Enter your GTM ID from google ads
        </p>
        <div class="input-container">
          <label for="project-settings-gtm-id" trans>GTM ID <span style="color: red">*</span></label>
          <input type="gtm-id" id="project-settings-gtm-id" placeholder="GTM-XXXXXXXX" formControlName="GTMID" required />
        </div>
       <br>

          </ng-container>

      </div>
      <div class="settings-modal-actions">
        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="projectSettings.loading$ | async"
          trans
        >
          Save
        </button>
      </div>
    </form>
  </mat-tab>
  <mat-tab [label]="'Export' | trans">
    <export-tab></export-tab>
  </mat-tab>
  <mat-tab [label]="'Builder' | trans">
    <builder-settings-tab></builder-settings-tab>
  </mat-tab>
</mat-tab-group>
